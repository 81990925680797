<template>
  <div>
    <v-parallax
        dark
        src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
    >
      <v-row
          align="center"
          justify="center"
      >
        <v-col
            class="text-center"
            cols="12"
        >
          <h1>
            <v-icon class="forbidden-icon">mdi-hand-left</v-icon>
          </h1>
          <h2 class="subheading">
            Forbidden
          </h2>
        </v-col>
      </v-row>
    </v-parallax>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>